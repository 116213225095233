import { Box, Typography } from '@material-ui/core';
import { PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container, Footer, Header, Layout, Root } from '../components';
import { Page } from './page';

const PageNotFound: React.FC<PageProps<null, { pages: Page[] }>> = ({
  pageContext: { pages },
}) => {
  const { t } = useTranslation('404');

  return (
    <>
      <Helmet>
        <title>Yes2Chess</title>
      </Helmet>
      <Root>
        <Layout>
          <Header pages={pages}>
            <Container>
              <Box textAlign="center" clone>
                <Typography component="div">{t('message')}</Typography>
              </Box>
            </Container>
            <Footer />
          </Header>
        </Layout>
      </Root>
    </>
  );
};

export default PageNotFound;
